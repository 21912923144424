import React, { FC } from "react";

import TextField from "@material-ui/core/TextField";
import { ThemeProvider } from "@material-ui/styles";
import { createMuiTheme } from "@material-ui/core/styles";
import ButtonBase from "@material-ui/core/ButtonBase";
import { FaPaperPlane } from "react-icons/fa";

import smallCloudFlat from "../../images/svg/SmallCloudFlat.svg";
import leftCloud from "../../images/svg/LeftCloudBlue.svg";
import rightCloud from "../../images/svg/RightCloudBlue.svg";

import style from "./contact.module.scss";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#0082a4",
    },
  },
});

const Contact: FC = () => {
  return (
    <div className={style.backgroundContainer}>
      <img src={smallCloudFlat} className={style.smallCloud} />
      <img src={leftCloud} className={style.leftCloud} />
      <img src={rightCloud} className={style.rightCloud} />
      <div className={`${style.contact}`}>
        <div className={style.heading}>
          <h2>Contact Us</h2>
          {/* <hr /> */}
          <p>Like to know more about our company or products?</p>
          <p>Connect with us below.</p>
        </div>
        <form
          action="/form-submitted"
          name="contact"
          method="post"
          netlify-honeypot="bot-field"
          data-netlify="true"
          className={style.form}
        >
          <input type="hidden" name="bot-field" />
          <input type="hidden" name="form-name" value="contact" />
          <ThemeProvider theme={theme}>
            <TextField
              className={style.textField}
              name="name"
              id="name"
              label="Name"
              variant="outlined"
              required
            />
            <TextField
              className={style.textField}
              name="email"
              id="email"
              label="Email"
              variant="outlined"
              required
            />
            <TextField
              className={style.textField}
              name="subject"
              id="subject"
              label="Subject"
              variant="outlined"
              required
            />
            <TextField
              className={style.textField}
              name="message"
              id="message"
              label="Message"
              variant="outlined"
              multiline
              rows={10}
              required
            />
          </ThemeProvider>

          <ButtonBase type="submit" className={`${style.btnSubmit}`}>
            Send
            <FaPaperPlane className={style.submitIcon} />
          </ButtonBase>
        </form>
      </div>
    </div>
  );
};

export default Contact;
// onSubmit={event => {
//   event.preventDefault();
//   // TODO: do something with form values
//   navigate("/form-submitted/");
// }}
