import React, { useEffect, useContext } from "react";

import { PageContext, pages } from "../context/page-context";

import Contact from "../components/contact/contact";
import PageLayout from "../components/layout/page-layout";
import style from "../styles/page-styles/contact.module.scss";

function ContactPage() {
  const pageContext = useContext(PageContext);
  useEffect(() => {
    pageContext.setCurrentPage(pages.contact);
  }, [pageContext.currentPage]);
  return (
    <PageLayout>
      <section className={style.pageContent}>
        <Contact />
      </section>
    </PageLayout>
  );
}
export default ContactPage;
